var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('el-col',{attrs:{"sm":24,"md":16}},[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("Editar")])]),_c('div',{staticClass:"text item"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('el-form',{attrs:{"label-position":"top","label-width":"100px","model":_vm.form}},[_c('el-form-item',{attrs:{"label":"Título","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"Titulo","rules":"required|min:5|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Descripción corta","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"decription short","rules":"required|min:1|max:300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Imagen*"}},[(_vm.form.image)?_c('div',[_c('el-image',{attrs:{"src":_vm.$urlGlobalApi +_vm.form.image}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"placeholder"},slot:"placeholder"},[_vm._v(" Loading"),_c('span',{staticClass:"dot"},[_vm._v("...")])])])],1):_vm._e(),_c('el-upload',{attrs:{"drag":"","action":"","name":"image","http-request":_vm.postImage,"on-success":_vm.handleAvatarSuccess,"multiple":""}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("Suelta tu foto aquí o "),_c('em',[_vm._v("haz clic para cargar")])]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("Solo archivos jpg/png con un tamaño menor de 500kb (800 x 600)")])])],1),_c('el-form-item',{attrs:{"label":"Tipo","size":"mini"}},[_c('ValidationProvider',{attrs:{"name":"tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-select',{attrs:{"clearable":"","size":"mini","filterable":"","placeholder":"Tipo"},on:{"change":function($event){return _vm.handleSearch()}},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},_vm._l((_vm.optionType),function(itemType){return _c('el-option',{key:itemType.value,attrs:{"label":itemType.label,"value":itemType.value}})}),1),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.form.type == 'text')?_c('div',[_c('el-form-item',{attrs:{"label":"Contenido"}},[_c('tinyeditor',{attrs:{"api-key":"epme9s1ejp9yit6h3lokaj05yohsvjm61r9y7kdrvm0wm9ex","init":{
                      height: 500,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    }},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('el-form-item',{attrs:{"label":"Cargar Archivo .pdf"}},[_c('el-upload',{attrs:{"name":"image","action":"#","http-request":_vm.postFile,"before-upload":_vm.beforeFIleUpload,"on-success":_vm.handleAvatarSuccessFile}},[_c('el-button',{attrs:{"slot":"trigger","size":"small","type":"primary"},slot:"trigger"},[_vm._v("Selecciona un archivo .pdf")])],1)],1)],1):_vm._e(),(_vm.form.type == 'video')?_c('div',[_c('el-form-item',{attrs:{"label":"Url de video"}},[_c('el-input',{attrs:{"size":"mini"},model:{value:(_vm.form.video),callback:function ($$v) {_vm.$set(_vm.form, "video", $$v)},expression:"form.video"}})],1)],1):_vm._e(),(_vm.form.type == 'audio')?_c('div',[_c('el-form-item',{attrs:{"label":"Archivo audio .mp3"}},[_c('audio',{attrs:{"controls":""}},[_c('source',{attrs:{"src":_vm.$urlGlobalApi +_vm.form.audio,"type":"audio/mp3"}}),_vm._v(" Tu navegador no soporta audio HTML5. ")]),_c('el-upload',{attrs:{"name":"file","action":"#","http-request":_vm.postAudio,"before-upload":_vm.beforeAudioUpload,"on-success":_vm.handleAvatarSuccessAudio}},[_c('el-button',{attrs:{"slot":"trigger","size":"small","type":"primary"},slot:"trigger"},[_vm._v("Selecciona un archivo .mp3")])],1)],1)],1):_vm._e(),_c('el-form-item',{attrs:{"label":"Fecha de publicación"}},[_c('ValidationProvider',{attrs:{"name":"Fecha de publicación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('el-date-picker',{attrs:{"size":"mini","type":"date","format":"dd-MM-yyyy","placeholder":"Fecha"},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","disabled":invalid,"loading":_vm.loading,"icon":"el-icon-upload2"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onUpdate($event)}}},[_vm._v(" Actualizar")])],1)],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }